<template>
  <div>
    <v-card class="cardEspecifico">
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12" sm="4">
            <v-autocomplete
              :value="filter.cnpjEmpresa"
              id="idACEmpresa"
              :items="establishmentsParsed"
              item-text="socialName"
              item-value="cnpjFilial"
              label="Estabelecimentos"
              placeholder="Todos"
              no-data-text="Selecione uma empresa primeiro"
              dense
              outlined
              multiple
              rounded
              clearable
              hide-details
              append-icon="mdi-chevron-down"
              persistent-placeholder
              @input="(items) => changeFilter({ prop: 'cnpjEmpresa', value: items })"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  {{ `${filter.cnpjEmpresa.length} selecionado${filter.cnpjEmpresa.length > 1 ? 's' : ''}` }}
                </span>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item #default="{ active }" v-bind="attrs" :class="{ 'pl-10': item.group }" v-on="on">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" :id="item.cnpjFilial + 'check'"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content :id="item.cnpjFilial">
                    <v-list-item-title>{{ item.socialName }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.stateCode || 'Sem dado' }} - {{ item.city || 'Sem dado' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              :value="filter.anoApur"
              :items="periods"
              label="Período de apuração"
              id="idACPerApur"
              placeholder="Todos"
              outlined
              rounded
              dense
              append-icon="mdi-chevron-down"
              multiple
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
              @input="(items) => changeFilter({ prop: 'anoApur', value: items })"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  {{ `${filter.anoApur.length} selecionado${filter.anoApur.length > 1 ? 's' : ''}` }}
                </span>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item #default="{ active }" v-bind="attrs" v-on="on" :id="item">
                  <v-list-item-action>
                    <v-checkbox :input-value="active" :id="item + 'check'"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content :id="item + 'content'">
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DashboardFilter',

  props: {
    multiple: Boolean,
    branch: Boolean,
  },

  data() {
    return {
      filterParam: {},
      cnpjsMatriz: [],
      itemsEstabelecimentos: [],
    };
  },

  computed: {
    ...mapState('ecac', {
      filter: (state) => state.filter,
      companies: (state) => state.companies,
      establishments: (state) => state.establishments,
      periods: (state) => state.periods,
    }),

    establishmentsParsed() {
      let items = [];
      let groupMatriz = groupBy(this.establishments, 'company');
      groupMatriz = Object.entries(groupMatriz).map(([key, value]) => ({
        company: key,
        establishments: value,
      }));
      groupMatriz = sortBy(groupMatriz, ['company']);
      groupMatriz.forEach((item, index) => {
        if (index > 0) items.push({ divider: true });
        const group = item.company;
        items.push({ header: group });
        item.establishments.forEach((innerItem) => {
          innerItem.group = group;
          items.push(innerItem);
        });
      });

      return items;
    },
  },

  watch: {
    filter() {
      this.applyFilterOnRoute();
    },
  },

  created() {
    // this.$store.dispatch('ecac/init', {});
    this.$store.dispatch('ecac/init', this.getParamsForFilter());
    this.montarFiltro();
  },

  methods: {
    ...mapActions('ecac', ['changeFilter']),

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    async applyFilterOnRoute() {
      let filter = { query: { filter: JSON.stringify(this.filter) } };
      await this.$router.replace(filter);
    },

    getParamsForFilter() {
      const filter = {};
      if (!this.isEmpty(this.$route.query)) {
        let params = {};
        if (typeof this.$route.query.filter === 'string') {
          params = JSON.parse(this.$route.query.filter);
        } else {
          params = cloneDeep(this.$route.query.filter);
        }
        let competencias = params.anoApur;

        if (Array.isArray(competencias)) {
          filter.anoApur = competencias.map((item) => parseInt(item));
        } else if (!Array.isArray(competencias)) {
          filter.anoApur = [parseInt(competencias)];
        } else {
          console.log('');
        }

        let estabelecimentos = params.cnpjEmpresa;

        if (Array.isArray(estabelecimentos)) {
          filter.cnpjEmpresa = estabelecimentos.map((i) => i);
        } else if (!Array.isArray(estabelecimentos) && this.multiple === true) {
          filter.cnpjEmpresa = [estabelecimentos].map((i) => i);
        } else if (!Array.isArray(estabelecimentos) && this.multiple != true) {
          filter.cnpjEmpresa = estabelecimentos;
        } else {
          console.log('');
        }
      }
      return filter;
    },

    montarFiltro() {
      // this.fetchCnpjs();
      this.itemsEmpresa = this.cnpjsMatriz.map((item) => ({ text: item.id, value: item.id }));
    },

    async pesquisar() {
      if (this.filter.anoApur.length == 0 || this.filter.cnpjEmpresa.length == 0) {
        // alert('Preencher todos os filtros por favor.');
      } else {
        // MANDA OS DADOS PARA A URL
        await this.applyFilterOnRoute();
      }
    },
  },
};
</script>
