var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "cardEspecifico" },
        [
          _c(
            "v-card-text",
            { staticClass: "text--primary" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          value: _vm.filter.cnpjEmpresa,
                          id: "idACEmpresa",
                          items: _vm.establishmentsParsed,
                          "item-text": "socialName",
                          "item-value": "cnpjFilial",
                          label: "Estabelecimentos",
                          placeholder: "Todos",
                          "no-data-text": "Selecione uma empresa primeiro",
                          dense: "",
                          outlined: "",
                          multiple: "",
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "append-icon": "mdi-chevron-down",
                          "persistent-placeholder": "",
                        },
                        on: {
                          input: (items) =>
                            _vm.changeFilter({
                              prop: "cnpjEmpresa",
                              value: items,
                            }),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.cnpjEmpresa.length
                                            } selecionado${
                                              _vm.filter.cnpjEmpresa.length > 1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function ({ item, attrs, on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: { "pl-10": item.group },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ active }) {
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active,
                                                          id:
                                                            item.cnpjFilial +
                                                            "check",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      attrs: {
                                                        id: item.cnpjFilial,
                                                      },
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.socialName
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.stateCode ||
                                                                  "Sem dado"
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                item.city ||
                                                                  "Sem dado"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          value: _vm.filter.anoApur,
                          items: _vm.periods,
                          label: "Período de apuração",
                          id: "idACPerApur",
                          placeholder: "Todos",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          "append-icon": "mdi-chevron-down",
                          multiple: "",
                          clearable: "",
                          "hide-details": "",
                          "deletable-chips": "",
                          "persistent-placeholder": "",
                        },
                        on: {
                          input: (items) =>
                            _vm.changeFilter({ prop: "anoApur", value: items }),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.anoApur.length
                                            } selecionado${
                                              _vm.filter.anoApur.length > 1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function ({ item, attrs, on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { id: item },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ active }) {
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active,
                                                          id: item + "check",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      attrs: {
                                                        id: item + "content",
                                                      },
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }